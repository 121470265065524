/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class RestoreJobInitiationResponse {
  /**
  * The ID of the job
  */
  'jobId': string;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "jobId",
      "baseName": "jobId",
      "type": "string",
      "format": "uuid"
    }  ];

  static getAttributeTypeMap() {
    return RestoreJobInitiationResponse.attributeTypeMap;
  }

  public constructor() {
  }
}

