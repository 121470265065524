/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { AuditLogAction } from '../models/AuditLogAction';
import { AuditLogEntitiesInner } from '../models/AuditLogEntitiesInner';
import { RequestDetails } from '../models/RequestDetails';

export class AuditLog {
  /**
  * The ID of the audit log
  */
  'id'?: string;
  /**
  * The ID of the user who initiated the request
  */
  'initiatorId'?: string;
  /**
  * The name of who initiated the request
  */
  'initiatorName'?: string;
  'requestDetails'?: RequestDetails;
  /**
  * The http verb of the request
  */
  'verb'?: string;
  /**
  * The path of the request
  */
  'path'?: string;
  /**
  * The route path the request was sent to, with path parameters replaced with a placeholder. For example:    - Requests sent to `/users/123` are normalized `/users/:id`.   - Requests sent to `/users/123/query` are normalized `/users/:id/query`.
  */
  'normalizedPath'?: string;
  /**
  * The status code in the response to the audited request.
  */
  'statusCode'?: number;
  /**
  * The operation name of the request
  */
  'operationName'?: string;
  'action'?: AuditLogAction;
  'entities'?: Array<AuditLogEntitiesInner>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "id",
      "baseName": "id",
      "type": "string",
      "format": ""
    },
    {
      "name": "initiatorId",
      "baseName": "initiatorId",
      "type": "string",
      "format": ""
    },
    {
      "name": "initiatorName",
      "baseName": "initiatorName",
      "type": "string",
      "format": ""
    },
    {
      "name": "requestDetails",
      "baseName": "requestDetails",
      "type": "RequestDetails",
      "format": ""
    },
    {
      "name": "verb",
      "baseName": "verb",
      "type": "string",
      "format": ""
    },
    {
      "name": "path",
      "baseName": "path",
      "type": "string",
      "format": ""
    },
    {
      "name": "normalizedPath",
      "baseName": "normalizedPath",
      "type": "string",
      "format": ""
    },
    {
      "name": "statusCode",
      "baseName": "statusCode",
      "type": "number",
      "format": ""
    },
    {
      "name": "operationName",
      "baseName": "operationName",
      "type": "string",
      "format": ""
    },
    {
      "name": "action",
      "baseName": "action",
      "type": "AuditLogAction",
      "format": ""
    },
    {
      "name": "entities",
      "baseName": "entities",
      "type": "Array<AuditLogEntitiesInner>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return AuditLog.attributeTypeMap;
  }

  public constructor() {
  }
}



