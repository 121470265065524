/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export enum ResourceType {
  Ec2 = 'EC2',
  Rds = 'RDS',
  S3 = 'S3',
  Ebs = 'EBS',
  Efs = 'EFS',
  Gce = 'GCE',
  VirtualMachine = 'VirtualMachine',
  CloudSql = 'CloudSQL',
  FileShare = 'FileShare',
  AtlasCluster = 'AtlasCluster',
  StorageAccount = 'StorageAccount',
  Disk = 'Disk',
  SqlServer = 'SqlServer',
  SqlDatabase = 'SqlDatabase'
}
