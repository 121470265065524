/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { SnapshotProperties } from '../models/SnapshotProperties';
import { SnapshotStatus } from '../models/SnapshotStatus';

export class Snapshot {
  /**
  * The ID of the snapshot
  */
  'id': string;
  /**
  * The ID of the project
  */
  'projectId'?: string;
  /**
  * The date the snapshot creation was initiated
  */
  'createTime': Date;
  /**
  * The date the snapshot was taken
  */
  'pointInTime'?: Date;
  /**
  * The ID of the vault the snapshot is stored in
  */
  'vaultId': string;
  /**
  * The ID of the resource the snapshot was taken from
  */
  'resourceId': string;
  /**
  * The date the snapshot will expire
  */
  'expirationTime'?: Date;
  /**
  * The backup retention in days
  */
  'backupRetention'?: number;
  /**
  * Whether the snapshot is locked
  */
  'lock': boolean;
  'status': SnapshotStatus;
  'properties'?: SnapshotProperties;
  /**
  * The full size of the snapshot in bytes
  */
  'fullSizeBytes'?: number;
  /**
  * The incremental size of the snapshot in bytes
  */
  'incrementalSizeBytes'?: number;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "id",
      "baseName": "id",
      "type": "string",
      "format": ""
    },
    {
      "name": "projectId",
      "baseName": "projectId",
      "type": "string",
      "format": ""
    },
    {
      "name": "createTime",
      "baseName": "createTime",
      "type": "Date",
      "format": "date-time"
    },
    {
      "name": "pointInTime",
      "baseName": "pointInTime",
      "type": "Date",
      "format": "date-time"
    },
    {
      "name": "vaultId",
      "baseName": "vaultId",
      "type": "string",
      "format": ""
    },
    {
      "name": "resourceId",
      "baseName": "resourceId",
      "type": "string",
      "format": ""
    },
    {
      "name": "expirationTime",
      "baseName": "expirationTime",
      "type": "Date",
      "format": "date-time"
    },
    {
      "name": "backupRetention",
      "baseName": "backupRetention",
      "type": "number",
      "format": ""
    },
    {
      "name": "lock",
      "baseName": "lock",
      "type": "boolean",
      "format": ""
    },
    {
      "name": "status",
      "baseName": "status",
      "type": "SnapshotStatus",
      "format": ""
    },
    {
      "name": "properties",
      "baseName": "properties",
      "type": "SnapshotProperties",
      "format": ""
    },
    {
      "name": "fullSizeBytes",
      "baseName": "fullSizeBytes",
      "type": "number",
      "format": "int64"
    },
    {
      "name": "incrementalSizeBytes",
      "baseName": "incrementalSizeBytes",
      "type": "number",
      "format": "int64"
    }  ];

  static getAttributeTypeMap() {
    return Snapshot.attributeTypeMap;
  }

  public constructor() {
  }
}



