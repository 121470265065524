/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class VolumeSettings {
  /**
  * The type of the volume
  */
  'type': string;
  /**
  * The size of the volume
  */
  'sizeBytes': number;
  /**
  * The IOPS of the volume
  */
  'iops'?: number;
  /**
  * The throughput of the volume
  */
  'throughput'?: number;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "type",
      "baseName": "type",
      "type": "string",
      "format": ""
    },
    {
      "name": "sizeBytes",
      "baseName": "sizeBytes",
      "type": "number",
      "format": "int64"
    },
    {
      "name": "iops",
      "baseName": "iops",
      "type": "number",
      "format": ""
    },
    {
      "name": "throughput",
      "baseName": "throughput",
      "type": "number",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return VolumeSettings.attributeTypeMap;
  }

  public constructor() {
  }
}

