/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { Environment } from '../models/Environment';

export class EnvironmentDetails {
  'environment'?: Environment;
  'isOverridden'?: boolean;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "environment",
      "baseName": "environment",
      "type": "Environment",
      "format": ""
    },
    {
      "name": "isOverridden",
      "baseName": "isOverridden",
      "type": "boolean",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return EnvironmentDetails.attributeTypeMap;
  }

  public constructor() {
  }
}



