/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class SourceStorage {
  /**
  * The storage size (in bytes) of the source storage
  */
  'sizeBytes'?: number;
  /**
  * The source storage volumes of the inventory item
  */
  'volumeCount'?: number;
  /**
  * The source storage files of the inventory item
  */
  'filesCount'?: number;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "sizeBytes",
      "baseName": "sizeBytes",
      "type": "number",
      "format": "int64"
    },
    {
      "name": "volumeCount",
      "baseName": "volumeCount",
      "type": "number",
      "format": "int64"
    },
    {
      "name": "filesCount",
      "baseName": "filesCount",
      "type": "number",
      "format": "int64"
    }  ];

  static getAttributeTypeMap() {
    return SourceStorage.attributeTypeMap;
  }

  public constructor() {
  }
}

