/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export enum RestoreType {
  Snapshot = 'RDS_DATABASE_TO_RDS_SNAPSHOT',
  Database = 'RDS_DATABASE_TO_RDS_DATABASE'
}
