import {
  SearchInputCloudProviderEnum,
  type Configuration,
} from '@repo/api-gw-sdk';
import { QueryDBApi, SearchApi } from '@repo/api-gw-sdk';

import { toJsonText } from '@/components/queryBuilder/QueryLanguageConverter';
import type { Condition } from '@/types/advanceFilter';

export const search = (config: Configuration, currentProjectId: string) => {
  const searchApi = new SearchApi(config);
  const queryDbApi = new QueryDBApi(config);
  return {
    files: {
      execute: (
        term: string,
        limit: number,
        cloudProvider: SearchInputCloudProviderEnum,
        queryExecutionId?: string,
        nextToken?: string,
        filters?: Condition,
        startDate?: Date,
        endDate?: Date
      ) =>
        searchApi.search(currentProjectId, {
          cloudProvider,
          query: term,
          limit,
          nextToken,
          queryExecutionId,
          filters: filters ? toJsonText(filters) : undefined,
          startDate: startDate,
          endDate: endDate,
        }),
    },
    dbs: {
      execute: (term: string, pageIndex: number, pageSize: number) =>
        queryDbApi.queryDB(
          currentProjectId,
          {
            cloudProvider: SearchInputCloudProviderEnum.Aws,
            query: term,
          },
          pageIndex,
          pageSize
        ),
    },
  };
};
