/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { AuditLogFilterConditions } from '../models/AuditLogFilterConditions';
import { SortLogsBy } from '../models/SortLogsBy';

export class ListAuditLogRequest {
  'filters'?: AuditLogFilterConditions;
  'sorts'?: Array<SortLogsBy>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "filters",
      "baseName": "filters",
      "type": "AuditLogFilterConditions",
      "format": ""
    },
    {
      "name": "sorts",
      "baseName": "sorts",
      "type": "Array<SortLogsBy>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return ListAuditLogRequest.attributeTypeMap;
  }

  public constructor() {
  }
}

