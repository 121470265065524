/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export enum SnapshotStatus {
  Pending = 'PENDING',
  WaitingForBackup = 'WAITING_FOR_BACKUP',
  BackupSnapshotInProgress = 'BACKUP_SNAPSHOT_IN_PROGRESS',
  InvalidForBackup = 'INVALID_FOR_BACKUP',
  Completed = 'COMPLETED',
  PartialCompleted = 'PARTIAL_COMPLETED',
  Failed = 'FAILED',
  PendingDeletion = 'PENDING_DELETION'
}
