import type {
  ConvertVolumeToSnapshotRequest,
  Configuration,
} from '@repo/api-gw-sdk';
import { SnapshotsApi } from '@repo/api-gw-sdk';

import type { HttpClient } from './httpClient';

export const convert = (
  httpClient: HttpClient,
  config: Configuration,
  currentProjectId: string
) => {
  const snapshotsApi = new SnapshotsApi(config);

  return {
    volume: (
      resourceId: string,
      volumeId: string,
      payload: ConvertVolumeToSnapshotRequest
    ) =>
      snapshotsApi.convertVolumeToSnapshot(
        currentProjectId,
        resourceId,
        volumeId,
        payload
      ),
  };
};
