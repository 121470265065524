/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { SnapshotPropertiesVolumePropertiesInner } from '../models/SnapshotPropertiesVolumePropertiesInner';

/**
* Details regarding the resource at the current snapshot
*/
export class SnapshotProperties {
  /**
  * The type of the instance
  */
  'instanceType'?: string;
  /**
  * The ID of the subnet
  */
  'subnetId'?: string;
  /**
  * The security groups associated with the resource
  */
  'securityGroupIds'?: Array<string>;
  /**
  * The instance profile
  */
  'instanceProfileName'?: string;
  /**
  * The tags associated with the resource
  */
  'tags'?: { [key: string]: string; } | null;
  'volumeProperties'?: Array<SnapshotPropertiesVolumePropertiesInner>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "instanceType",
      "baseName": "instanceType",
      "type": "string",
      "format": ""
    },
    {
      "name": "subnetId",
      "baseName": "subnetId",
      "type": "string",
      "format": ""
    },
    {
      "name": "securityGroupIds",
      "baseName": "securityGroupIds",
      "type": "Array<string>",
      "format": ""
    },
    {
      "name": "instanceProfileName",
      "baseName": "instanceProfileName",
      "type": "string",
      "format": ""
    },
    {
      "name": "tags",
      "baseName": "tags",
      "type": "{ [key: string]: string; }",
      "format": ""
    },
    {
      "name": "volumeProperties",
      "baseName": "volumeProperties",
      "type": "Array<SnapshotPropertiesVolumePropertiesInner>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return SnapshotProperties.attributeTypeMap;
  }

  public constructor() {
  }
}

