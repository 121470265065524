/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { FilePath } from '../models/FilePath';

export class RestoreFilesRequest {
  /**
  * The files to restore
  */
  'files': Array<FilePath>;
  /**
  * The ID of the restore account (target)
  */
  'restoreAccountId': string;
  /**
  * The name of the bucket to restore the files to
  */
  'restoreBucketName': string;
  /**
  * Optional encryption key ID for a new restore bucket
  */
  'encryptionKeyId'?: string;
  /**
  * The prefix to use for the restored files in the bucket
  */
  'restoreBucketPrefix'?: string;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "files",
      "baseName": "files",
      "type": "Array<FilePath>",
      "format": ""
    },
    {
      "name": "restoreAccountId",
      "baseName": "restoreAccountId",
      "type": "string",
      "format": ""
    },
    {
      "name": "restoreBucketName",
      "baseName": "restoreBucketName",
      "type": "string",
      "format": ""
    },
    {
      "name": "encryptionKeyId",
      "baseName": "encryptionKeyId",
      "type": "string",
      "format": ""
    },
    {
      "name": "restoreBucketPrefix",
      "baseName": "restoreBucketPrefix",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return RestoreFilesRequest.attributeTypeMap;
  }

  public constructor() {
  }
}

