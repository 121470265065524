import { BaseAPIRequestFactory, RequiredError } from './baseapi';
import type { Configuration } from '../configuration';
import { RequestContext, HttpMethod, ResponseContext, HttpInfo } from '../http/http';
import { ObjectSerializer } from '../models/ObjectSerializer';
import { ApiException } from './exception';
import { isCodeInRange } from '../util';
import type {SecurityAuthentication} from '../auth/auth';


import { ConvertVolumeToSnapshotRequest } from '../models/ConvertVolumeToSnapshotRequest';
import { ConvertVolumeToSnapshotResponse } from '../models/ConvertVolumeToSnapshotResponse';
import { GetSnapshotResponse } from '../models/GetSnapshotResponse';
import { ListInventorySnapshotsResponse } from '../models/ListInventorySnapshotsResponse';
import { RestoreJobInitiationResponse } from '../models/RestoreJobInitiationResponse';
import { RestoreVolumeRequest } from '../models/RestoreVolumeRequest';

/**
 * no description
 */
export class SnapshotsApiRequestFactory extends BaseAPIRequestFactory {

  /**
   * Convert Volume to Snapshot
   * @param projectId The project ID
   * @param id The inventory item ID
   * @param snapshotId The ID of the eon snapshot
   * @param convertVolumeToSnapshotRequest The request body for converting a volume
   */
  public async convertVolumeToSnapshot(projectId: string, id: string, snapshotId: string, convertVolumeToSnapshotRequest: ConvertVolumeToSnapshotRequest, _options?: Configuration): Promise<RequestContext> {
    let _config;
    if (!_options) {
      _config = this.configuration;
    } else {
      _config = Object.assign({}, _options, this.configuration);
    }
    


    // verify required parameter 'projectId' is not null or undefined
    if (projectId === null || projectId === undefined) {
      throw new RequiredError("SnapshotsApi", "convertVolumeToSnapshot", "projectId");
    }


    // verify required parameter 'id' is not null or undefined
    if (id === null || id === undefined) {
      throw new RequiredError("SnapshotsApi", "convertVolumeToSnapshot", "id");
    }


    // verify required parameter 'snapshotId' is not null or undefined
    if (snapshotId === null || snapshotId === undefined) {
      throw new RequiredError("SnapshotsApi", "convertVolumeToSnapshot", "snapshotId");
    }


    // verify required parameter 'convertVolumeToSnapshotRequest' is not null or undefined
    if (convertVolumeToSnapshotRequest === null || convertVolumeToSnapshotRequest === undefined) {
      throw new RequiredError("SnapshotsApi", "convertVolumeToSnapshot", "convertVolumeToSnapshotRequest");
    }


    // Path Params
    const localVarPath = '/v1/projects/{projectId}/resources/{id}/snapshots/{snapshotId}/convert'
      .replace('{' + 'projectId' + '}', encodeURIComponent(String(projectId)))
      .replace('{' + 'id' + '}', encodeURIComponent(String(id)))
      .replace('{' + 'snapshotId' + '}', encodeURIComponent(String(snapshotId)));

    let signal = _config.timeout ? AbortSignal.timeout(_config.timeout) : undefined;
    // Make Request Context
    const requestContext = _config.baseServer!.makeRequestContext(localVarPath, HttpMethod.POST, signal);
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


    // Body Params
    const contentType = "application/json"
    requestContext.setHeaderParam("Content-Type", contentType);
    const serializedBody = JSON.stringify(ObjectSerializer.serialize(convertVolumeToSnapshotRequest, "ConvertVolumeToSnapshotRequest", ""));
    requestContext.setBody(serializedBody);

    let authMethod: SecurityAuthentication | undefined;
    // Apply auth methods
    authMethod = _config.authMethods!["ApiKeyAuth"]
    if (authMethod?.applySecurityAuthentication) {
      await authMethod?.applySecurityAuthentication(requestContext);
    }

    return requestContext;
  }

  /**
   * Get Snapshot
   * @param id ID of the snapshot
   * @param projectId The project ID
   */
  public async getSnapshot(id: string, projectId: string, _options?: Configuration): Promise<RequestContext> {
    let _config;
    if (!_options) {
      _config = this.configuration;
    } else {
      _config = Object.assign({}, _options, this.configuration);
    }
    


    // verify required parameter 'id' is not null or undefined
    if (id === null || id === undefined) {
      throw new RequiredError("SnapshotsApi", "getSnapshot", "id");
    }


    // verify required parameter 'projectId' is not null or undefined
    if (projectId === null || projectId === undefined) {
      throw new RequiredError("SnapshotsApi", "getSnapshot", "projectId");
    }


    // Path Params
    const localVarPath = '/v1/projects/{projectId}/snapshots/{id}'
      .replace('{' + 'id' + '}', encodeURIComponent(String(id)))
      .replace('{' + 'projectId' + '}', encodeURIComponent(String(projectId)));

    let signal = _config.timeout ? AbortSignal.timeout(_config.timeout) : undefined;
    // Make Request Context
    const requestContext = _config.baseServer!.makeRequestContext(localVarPath, HttpMethod.GET, signal);
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


    let authMethod: SecurityAuthentication | undefined;
    // Apply auth methods
    authMethod = _config.authMethods!["ApiKeyAuth"]
    if (authMethod?.applySecurityAuthentication) {
      await authMethod?.applySecurityAuthentication(requestContext);
    }

    return requestContext;
  }

  /**
   * List Resource Snapshots
   * @param id ID of the inventory item
   * @param projectId The project ID
   * @param nextToken The token for the next page
   * @param pageSize The numbers of items per page
   */
  public async listResourceSnapshots(id: string, projectId: string, nextToken?: string, pageSize?: number, _options?: Configuration): Promise<RequestContext> {
    let _config;
    if (!_options) {
      _config = this.configuration;
    } else {
      _config = Object.assign({}, _options, this.configuration);
    }
    


    // verify required parameter 'id' is not null or undefined
    if (id === null || id === undefined) {
      throw new RequiredError("SnapshotsApi", "listResourceSnapshots", "id");
    }


    // verify required parameter 'projectId' is not null or undefined
    if (projectId === null || projectId === undefined) {
      throw new RequiredError("SnapshotsApi", "listResourceSnapshots", "projectId");
    }




    // Path Params
    const localVarPath = '/v1/projects/{projectId}/resources/{id}/snapshots'
      .replace('{' + 'id' + '}', encodeURIComponent(String(id)))
      .replace('{' + 'projectId' + '}', encodeURIComponent(String(projectId)));

    let signal = _config.timeout ? AbortSignal.timeout(_config.timeout) : undefined;
    // Make Request Context
    const requestContext = _config.baseServer!.makeRequestContext(localVarPath, HttpMethod.GET, signal);
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

    // Query Params
    if (nextToken !== undefined) {
      requestContext.setQueryParam("nextToken", ObjectSerializer.serialize(nextToken, "string", ""));
    }

    // Query Params
    if (pageSize !== undefined) {
      requestContext.setQueryParam("pageSize", ObjectSerializer.serialize(pageSize, "number", ""));
    }


    let authMethod: SecurityAuthentication | undefined;
    // Apply auth methods
    authMethod = _config.authMethods!["ApiKeyAuth"]
    if (authMethod?.applySecurityAuthentication) {
      await authMethod?.applySecurityAuthentication(requestContext);
    }

    return requestContext;
  }

  /**
   * Restore Volume
   * @param projectId The project ID
   * @param id The inventory item ID
   * @param snapshotId The ID of the eon snapshot
   * @param restoreVolumeRequest The request body for restoring a volume
   */
  public async restoreVolume(projectId: string, id: string, snapshotId: string, restoreVolumeRequest: RestoreVolumeRequest, _options?: Configuration): Promise<RequestContext> {
    let _config;
    if (!_options) {
      _config = this.configuration;
    } else {
      _config = Object.assign({}, _options, this.configuration);
    }
    


    // verify required parameter 'projectId' is not null or undefined
    if (projectId === null || projectId === undefined) {
      throw new RequiredError("SnapshotsApi", "restoreVolume", "projectId");
    }


    // verify required parameter 'id' is not null or undefined
    if (id === null || id === undefined) {
      throw new RequiredError("SnapshotsApi", "restoreVolume", "id");
    }


    // verify required parameter 'snapshotId' is not null or undefined
    if (snapshotId === null || snapshotId === undefined) {
      throw new RequiredError("SnapshotsApi", "restoreVolume", "snapshotId");
    }


    // verify required parameter 'restoreVolumeRequest' is not null or undefined
    if (restoreVolumeRequest === null || restoreVolumeRequest === undefined) {
      throw new RequiredError("SnapshotsApi", "restoreVolume", "restoreVolumeRequest");
    }


    // Path Params
    const localVarPath = '/v1/projects/{projectId}/resources/{id}/snapshots/{snapshotId}/restore'
      .replace('{' + 'projectId' + '}', encodeURIComponent(String(projectId)))
      .replace('{' + 'id' + '}', encodeURIComponent(String(id)))
      .replace('{' + 'snapshotId' + '}', encodeURIComponent(String(snapshotId)));

    let signal = _config.timeout ? AbortSignal.timeout(_config.timeout) : undefined;
    // Make Request Context
    const requestContext = _config.baseServer!.makeRequestContext(localVarPath, HttpMethod.POST, signal);
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


    // Body Params
    const contentType = "application/json"
    requestContext.setHeaderParam("Content-Type", contentType);
    const serializedBody = JSON.stringify(ObjectSerializer.serialize(restoreVolumeRequest, "RestoreVolumeRequest", ""));
    requestContext.setBody(serializedBody);

    let authMethod: SecurityAuthentication | undefined;
    // Apply auth methods
    authMethod = _config.authMethods!["ApiKeyAuth"]
    if (authMethod?.applySecurityAuthentication) {
      await authMethod?.applySecurityAuthentication(requestContext);
    }

    return requestContext;
  }

}

export class SnapshotsApiResponseProcessor {

  /**
   * Unwraps the actual response sent by the server from the response context and deserializes the response content
   * to the expected objects
   *
   * @params response Response returned by the server for a request to convertVolumeToSnapshot
   * @throws ApiException if the response code was not in [200, 299]
   */
   public async convertVolumeToSnapshotWithHttpInfo(response: ResponseContext): Promise<HttpInfo<ConvertVolumeToSnapshotResponse >> {
    if (isCodeInRange("202", response.httpStatusCode)) {
      const body: ConvertVolumeToSnapshotResponse = ObjectSerializer.deserialize(
        await response.body.json(),
        "ConvertVolumeToSnapshotResponse", ""
      ) as ConvertVolumeToSnapshotResponse;
      return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
    }
    if (isCodeInRange("404", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Volume, snapshot or restore account not found", undefined, response.headers, response);
    }
    if (isCodeInRange("1XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Informational", undefined, response.headers, response);
    }
    if (isCodeInRange("3XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Redirect", undefined, response.headers, response);
    }
    if (isCodeInRange("4XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Client Error", body, response.headers, response);
    }
    if (isCodeInRange("5XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Internal Server Error", body, response.headers, response);
    }

    // Work around for missing responses in specification, e.g. for petstore.yaml
    if (response.httpStatusCode > 200 || response.httpStatusCode > 299) {
      throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers, response);
    }
    const body: ConvertVolumeToSnapshotResponse = await response.body.json() as ConvertVolumeToSnapshotResponse;
    return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
  }

  /**
   * Unwraps the actual response sent by the server from the response context and deserializes the response content
   * to the expected objects
   *
   * @params response Response returned by the server for a request to getSnapshot
   * @throws ApiException if the response code was not in [200, 299]
   */
   public async getSnapshotWithHttpInfo(response: ResponseContext): Promise<HttpInfo<GetSnapshotResponse >> {
    if (isCodeInRange("200", response.httpStatusCode)) {
      const body: GetSnapshotResponse = ObjectSerializer.deserialize(
        await response.body.json(),
        "GetSnapshotResponse", ""
      ) as GetSnapshotResponse;
      return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
    }
    if (isCodeInRange("404", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Snapshot not found", undefined, response.headers, response);
    }
    if (isCodeInRange("1XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Informational", undefined, response.headers, response);
    }
    if (isCodeInRange("3XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Redirect", undefined, response.headers, response);
    }
    if (isCodeInRange("4XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Client Error", body, response.headers, response);
    }
    if (isCodeInRange("5XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Internal Server Error", body, response.headers, response);
    }

    // Work around for missing responses in specification, e.g. for petstore.yaml
    if (response.httpStatusCode > 200 || response.httpStatusCode > 299) {
      throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers, response);
    }
    const body: GetSnapshotResponse = await response.body.json() as GetSnapshotResponse;
    return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
  }

  /**
   * Unwraps the actual response sent by the server from the response context and deserializes the response content
   * to the expected objects
   *
   * @params response Response returned by the server for a request to listResourceSnapshots
   * @throws ApiException if the response code was not in [200, 299]
   */
   public async listResourceSnapshotsWithHttpInfo(response: ResponseContext): Promise<HttpInfo<ListInventorySnapshotsResponse >> {
    if (isCodeInRange("200", response.httpStatusCode)) {
      const body: ListInventorySnapshotsResponse = ObjectSerializer.deserialize(
        await response.body.json(),
        "ListInventorySnapshotsResponse", ""
      ) as ListInventorySnapshotsResponse;
      return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
    }
    if (isCodeInRange("404", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Inventory item not found", undefined, response.headers, response);
    }
    if (isCodeInRange("1XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Informational", undefined, response.headers, response);
    }
    if (isCodeInRange("3XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Redirect", undefined, response.headers, response);
    }
    if (isCodeInRange("4XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Client Error", body, response.headers, response);
    }
    if (isCodeInRange("5XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Internal Server Error", body, response.headers, response);
    }

    // Work around for missing responses in specification, e.g. for petstore.yaml
    if (response.httpStatusCode > 200 || response.httpStatusCode > 299) {
      throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers, response);
    }
    const body: ListInventorySnapshotsResponse = await response.body.json() as ListInventorySnapshotsResponse;
    return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
  }

  /**
   * Unwraps the actual response sent by the server from the response context and deserializes the response content
   * to the expected objects
   *
   * @params response Response returned by the server for a request to restoreVolume
   * @throws ApiException if the response code was not in [200, 299]
   */
   public async restoreVolumeWithHttpInfo(response: ResponseContext): Promise<HttpInfo<RestoreJobInitiationResponse >> {
    if (isCodeInRange("202", response.httpStatusCode)) {
      const body: RestoreJobInitiationResponse = ObjectSerializer.deserialize(
        await response.body.json(),
        "RestoreJobInitiationResponse", ""
      ) as RestoreJobInitiationResponse;
      return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
    }
    if (isCodeInRange("404", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Volume, snapshot or restore account not found", undefined, response.headers, response);
    }
    if (isCodeInRange("1XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Informational", undefined, response.headers, response);
    }
    if (isCodeInRange("3XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Redirect", undefined, response.headers, response);
    }
    if (isCodeInRange("4XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Client Error", body, response.headers, response);
    }
    if (isCodeInRange("5XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Internal Server Error", body, response.headers, response);
    }

    // Work around for missing responses in specification, e.g. for petstore.yaml
    if (response.httpStatusCode > 200 || response.httpStatusCode > 299) {
      throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers, response);
    }
    const body: RestoreJobInitiationResponse = await response.body.json() as RestoreJobInitiationResponse;
    return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
  }

}
