/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


/**
* Field to sort by.
*/
export enum ResourceSortField {
  Id = 'id',
  BackupStatus = 'backupStatus',
  AccountId = 'accountId',
  ResourceName = 'resourceName',
  Environment = 'environment',
  ProviderResourceId = 'providerResourceId',
  SizeBytes = 'sizeBytes',
  SnapshotStorageSizeBytes = 'SnapshotStorageSizeBytes',
  EonSnapshotsCount = 'eonSnapshotsCount',
  OtherSnapshotsCount = 'otherSnapshotsCount',
  NewestSnapshotTime = 'newestSnapshotTime',
  OldestSnapshotTime = 'oldestSnapshotTime'
}
