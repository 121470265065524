import { BaseAPIRequestFactory, RequiredError } from './baseapi';
import type { Configuration } from '../configuration';
import { RequestContext, HttpMethod, ResponseContext, HttpInfo } from '../http/http';
import { ObjectSerializer } from '../models/ObjectSerializer';
import { ApiException } from './exception';
import { isCodeInRange } from '../util';
import type {SecurityAuthentication} from '../auth/auth';


import { PathSnapshotsRequest } from '../models/PathSnapshotsRequest';
import { PathSnapshotsResponse } from '../models/PathSnapshotsResponse';

/**
 * no description
 */
export class ListPathSnapshotsApiRequestFactory extends BaseAPIRequestFactory {

  /**
   * Get all snapshots for a specific file on resource
   * @param resourceId ID of the inventory item
   * @param cloudProvider The resource cloud provider
   * @param start The start date for the query
   * @param end The end date for the query
   * @param pageIndex The page index
   * @param pageSize The numbers of items per page
   * @param projectId The project ID
   * @param pathSnapshotsRequest The request body for searching records
   */
  public async listPathSnapshots(resourceId: string, cloudProvider: string, start: Date, end: Date, pageIndex: number, pageSize: number, projectId: string, pathSnapshotsRequest: PathSnapshotsRequest, _options?: Configuration): Promise<RequestContext> {
    let _config;
    if (!_options) {
      _config = this.configuration;
    } else {
      _config = Object.assign({}, _options, this.configuration);
    }
    


    // verify required parameter 'resourceId' is not null or undefined
    if (resourceId === null || resourceId === undefined) {
      throw new RequiredError("ListPathSnapshotsApi", "listPathSnapshots", "resourceId");
    }


    // verify required parameter 'cloudProvider' is not null or undefined
    if (cloudProvider === null || cloudProvider === undefined) {
      throw new RequiredError("ListPathSnapshotsApi", "listPathSnapshots", "cloudProvider");
    }


    // verify required parameter 'start' is not null or undefined
    if (start === null || start === undefined) {
      throw new RequiredError("ListPathSnapshotsApi", "listPathSnapshots", "start");
    }


    // verify required parameter 'end' is not null or undefined
    if (end === null || end === undefined) {
      throw new RequiredError("ListPathSnapshotsApi", "listPathSnapshots", "end");
    }


    // verify required parameter 'pageIndex' is not null or undefined
    if (pageIndex === null || pageIndex === undefined) {
      throw new RequiredError("ListPathSnapshotsApi", "listPathSnapshots", "pageIndex");
    }


    // verify required parameter 'pageSize' is not null or undefined
    if (pageSize === null || pageSize === undefined) {
      throw new RequiredError("ListPathSnapshotsApi", "listPathSnapshots", "pageSize");
    }


    // verify required parameter 'projectId' is not null or undefined
    if (projectId === null || projectId === undefined) {
      throw new RequiredError("ListPathSnapshotsApi", "listPathSnapshots", "projectId");
    }


    // verify required parameter 'pathSnapshotsRequest' is not null or undefined
    if (pathSnapshotsRequest === null || pathSnapshotsRequest === undefined) {
      throw new RequiredError("ListPathSnapshotsApi", "listPathSnapshots", "pathSnapshotsRequest");
    }


    // Path Params
    const localVarPath = '/projects/{projectId}/explorer/{cloudProvider}/{resourceId}/path/snapshots'
      .replace('{' + 'resourceId' + '}', encodeURIComponent(String(resourceId)))
      .replace('{' + 'cloudProvider' + '}', encodeURIComponent(String(cloudProvider)))
      .replace('{' + 'projectId' + '}', encodeURIComponent(String(projectId)));

    let signal = _config.timeout ? AbortSignal.timeout(_config.timeout) : undefined;
    // Make Request Context
    const requestContext = _config.baseServer!.makeRequestContext(localVarPath, HttpMethod.POST, signal);
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

    // Query Params
    if (start !== undefined) {
      requestContext.setQueryParam("start", ObjectSerializer.serialize(start, "Date", "date-time"));
    }

    // Query Params
    if (end !== undefined) {
      requestContext.setQueryParam("end", ObjectSerializer.serialize(end, "Date", "date-time"));
    }

    // Query Params
    if (pageIndex !== undefined) {
      requestContext.setQueryParam("pageIndex", ObjectSerializer.serialize(pageIndex, "number", ""));
    }

    // Query Params
    if (pageSize !== undefined) {
      requestContext.setQueryParam("pageSize", ObjectSerializer.serialize(pageSize, "number", ""));
    }


    // Body Params
    const contentType = "application/json"
    requestContext.setHeaderParam("Content-Type", contentType);
    const serializedBody = JSON.stringify(ObjectSerializer.serialize(pathSnapshotsRequest, "PathSnapshotsRequest", ""));
    requestContext.setBody(serializedBody);

    let authMethod: SecurityAuthentication | undefined;
    // Apply auth methods
    authMethod = _config.authMethods!["ApiKeyAuth"]
    if (authMethod?.applySecurityAuthentication) {
      await authMethod?.applySecurityAuthentication(requestContext);
    }

    return requestContext;
  }

}

export class ListPathSnapshotsApiResponseProcessor {

  /**
   * Unwraps the actual response sent by the server from the response context and deserializes the response content
   * to the expected objects
   *
   * @params response Response returned by the server for a request to listPathSnapshots
   * @throws ApiException if the response code was not in [200, 299]
   */
   public async listPathSnapshotsWithHttpInfo(response: ResponseContext): Promise<HttpInfo<PathSnapshotsResponse >> {
    if (isCodeInRange("200", response.httpStatusCode)) {
      const body: PathSnapshotsResponse = ObjectSerializer.deserialize(
        await response.body.json(),
        "PathSnapshotsResponse", ""
      ) as PathSnapshotsResponse;
      return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
    }
    if (isCodeInRange("404", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Inventory item not found or no snapshots found", undefined, response.headers, response);
    }
    if (isCodeInRange("1XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Informational", undefined, response.headers, response);
    }
    if (isCodeInRange("3XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Redirect", undefined, response.headers, response);
    }
    if (isCodeInRange("4XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Client Error", body, response.headers, response);
    }
    if (isCodeInRange("5XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Internal Server Error", body, response.headers, response);
    }

    // Work around for missing responses in specification, e.g. for petstore.yaml
    if (response.httpStatusCode > 200 || response.httpStatusCode > 299) {
      throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers, response);
    }
    const body: PathSnapshotsResponse = await response.body.json() as PathSnapshotsResponse;
    return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
  }

}
