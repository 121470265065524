/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { AppsDetails } from '../models/AppsDetails';
import { DataClassesDetails } from '../models/DataClassesDetails';
import { EnvironmentDetails } from '../models/EnvironmentDetails';

export class Classifications {
  'dataClassesDetails'?: DataClassesDetails;
  'environmentDetails'?: EnvironmentDetails;
  'appsDetails'?: AppsDetails;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "dataClassesDetails",
      "baseName": "dataClassesDetails",
      "type": "DataClassesDetails",
      "format": ""
    },
    {
      "name": "environmentDetails",
      "baseName": "environmentDetails",
      "type": "EnvironmentDetails",
      "format": ""
    },
    {
      "name": "appsDetails",
      "baseName": "appsDetails",
      "type": "AppsDetails",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return Classifications.attributeTypeMap;
  }

  public constructor() {
  }
}

