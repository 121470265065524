import { BaseAPIRequestFactory, RequiredError } from './baseapi';
import type { Configuration } from '../configuration';
import { RequestContext, HttpMethod, ResponseContext, HttpInfo } from '../http/http';
import { ObjectSerializer } from '../models/ObjectSerializer';
import { ApiException } from './exception';
import { isCodeInRange } from '../util';
import type {SecurityAuthentication} from '../auth/auth';


import { CancelExclusionFromBackupResponse } from '../models/CancelExclusionFromBackupResponse';
import { ExcludeFromBackupResponse } from '../models/ExcludeFromBackupResponse';
import { GetResourceResponse } from '../models/GetResourceResponse';
import { ListInventoryRequest } from '../models/ListInventoryRequest';
import { ListResourcesResponse } from '../models/ListResourcesResponse';
import { OverrideDataClassificationsRequest } from '../models/OverrideDataClassificationsRequest';
import { OverrideDataClassificationsResponse } from '../models/OverrideDataClassificationsResponse';
import { OverrideEnvironmentRequest } from '../models/OverrideEnvironmentRequest';
import { OverrideEnvironmentResponse } from '../models/OverrideEnvironmentResponse';

/**
 * no description
 */
export class ResourcesApiRequestFactory extends BaseAPIRequestFactory {

  /**
   * Cancel Resource Backup Exclusion
   * @param projectId The project ID
   * @param id The ID of the inventory item
   */
  public async cancelResourceBackupExclusion(projectId: string, id: string, _options?: Configuration): Promise<RequestContext> {
    let _config;
    if (!_options) {
      _config = this.configuration;
    } else {
      _config = Object.assign({}, _options, this.configuration);
    }
    


    // verify required parameter 'projectId' is not null or undefined
    if (projectId === null || projectId === undefined) {
      throw new RequiredError("ResourcesApi", "cancelResourceBackupExclusion", "projectId");
    }


    // verify required parameter 'id' is not null or undefined
    if (id === null || id === undefined) {
      throw new RequiredError("ResourcesApi", "cancelResourceBackupExclusion", "id");
    }


    // Path Params
    const localVarPath = '/v1/projects/{projectId}/resources/{id}/include'
      .replace('{' + 'projectId' + '}', encodeURIComponent(String(projectId)))
      .replace('{' + 'id' + '}', encodeURIComponent(String(id)));

    let signal = _config.timeout ? AbortSignal.timeout(_config.timeout) : undefined;
    // Make Request Context
    const requestContext = _config.baseServer!.makeRequestContext(localVarPath, HttpMethod.PATCH, signal);
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


    let authMethod: SecurityAuthentication | undefined;
    // Apply auth methods
    authMethod = _config.authMethods!["ApiKeyAuth"]
    if (authMethod?.applySecurityAuthentication) {
      await authMethod?.applySecurityAuthentication(requestContext);
    }

    return requestContext;
  }

  /**
   * Exclude Resource from Backup
   * @param projectId The project ID
   * @param id The ID of the inventory item
   */
  public async excludeResourceFromBackup(projectId: string, id: string, _options?: Configuration): Promise<RequestContext> {
    let _config;
    if (!_options) {
      _config = this.configuration;
    } else {
      _config = Object.assign({}, _options, this.configuration);
    }
    


    // verify required parameter 'projectId' is not null or undefined
    if (projectId === null || projectId === undefined) {
      throw new RequiredError("ResourcesApi", "excludeResourceFromBackup", "projectId");
    }


    // verify required parameter 'id' is not null or undefined
    if (id === null || id === undefined) {
      throw new RequiredError("ResourcesApi", "excludeResourceFromBackup", "id");
    }


    // Path Params
    const localVarPath = '/v1/projects/{projectId}/resources/{id}/exclude'
      .replace('{' + 'projectId' + '}', encodeURIComponent(String(projectId)))
      .replace('{' + 'id' + '}', encodeURIComponent(String(id)));

    let signal = _config.timeout ? AbortSignal.timeout(_config.timeout) : undefined;
    // Make Request Context
    const requestContext = _config.baseServer!.makeRequestContext(localVarPath, HttpMethod.PATCH, signal);
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


    let authMethod: SecurityAuthentication | undefined;
    // Apply auth methods
    authMethod = _config.authMethods!["ApiKeyAuth"]
    if (authMethod?.applySecurityAuthentication) {
      await authMethod?.applySecurityAuthentication(requestContext);
    }

    return requestContext;
  }

  /**
   * Get Resource
   * @param id ID of the inventory item
   * @param projectId The project ID
   */
  public async getResource(id: string, projectId: string, _options?: Configuration): Promise<RequestContext> {
    let _config;
    if (!_options) {
      _config = this.configuration;
    } else {
      _config = Object.assign({}, _options, this.configuration);
    }
    


    // verify required parameter 'id' is not null or undefined
    if (id === null || id === undefined) {
      throw new RequiredError("ResourcesApi", "getResource", "id");
    }


    // verify required parameter 'projectId' is not null or undefined
    if (projectId === null || projectId === undefined) {
      throw new RequiredError("ResourcesApi", "getResource", "projectId");
    }


    // Path Params
    const localVarPath = '/v1/projects/{projectId}/resources/{id}'
      .replace('{' + 'id' + '}', encodeURIComponent(String(id)))
      .replace('{' + 'projectId' + '}', encodeURIComponent(String(projectId)));

    let signal = _config.timeout ? AbortSignal.timeout(_config.timeout) : undefined;
    // Make Request Context
    const requestContext = _config.baseServer!.makeRequestContext(localVarPath, HttpMethod.GET, signal);
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


    let authMethod: SecurityAuthentication | undefined;
    // Apply auth methods
    authMethod = _config.authMethods!["ApiKeyAuth"]
    if (authMethod?.applySecurityAuthentication) {
      await authMethod?.applySecurityAuthentication(requestContext);
    }

    return requestContext;
  }

  /**
   * List Resources
   * @param projectId ID of the project whose resources you want to retrieve.
   * @param nextToken Cursor that points to the first record of the next page of results, returned in the previous response as &#x60;nextToken&#x60;.
   * @param pageSize Max number of items to return in the next page.
   * @param listInventoryRequest Sort and filter options.
   */
  public async listResources(projectId: string, nextToken?: string, pageSize?: number, listInventoryRequest?: ListInventoryRequest, _options?: Configuration): Promise<RequestContext> {
    let _config;
    if (!_options) {
      _config = this.configuration;
    } else {
      _config = Object.assign({}, _options, this.configuration);
    }
    


    // verify required parameter 'projectId' is not null or undefined
    if (projectId === null || projectId === undefined) {
      throw new RequiredError("ResourcesApi", "listResources", "projectId");
    }





    // Path Params
    const localVarPath = '/v1/projects/{projectId}/resources'
      .replace('{' + 'projectId' + '}', encodeURIComponent(String(projectId)));

    let signal = _config.timeout ? AbortSignal.timeout(_config.timeout) : undefined;
    // Make Request Context
    const requestContext = _config.baseServer!.makeRequestContext(localVarPath, HttpMethod.POST, signal);
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

    // Query Params
    if (nextToken !== undefined) {
      requestContext.setQueryParam("nextToken", ObjectSerializer.serialize(nextToken, "string", ""));
    }

    // Query Params
    if (pageSize !== undefined) {
      requestContext.setQueryParam("pageSize", ObjectSerializer.serialize(pageSize, "number", ""));
    }


    // Body Params
    const contentType = "application/json"
    requestContext.setHeaderParam("Content-Type", contentType);
    const serializedBody = JSON.stringify(ObjectSerializer.serialize(listInventoryRequest, "ListInventoryRequest", ""));
    requestContext.setBody(serializedBody);

    let authMethod: SecurityAuthentication | undefined;
    // Apply auth methods
    authMethod = _config.authMethods!["ApiKeyAuth"]
    if (authMethod?.applySecurityAuthentication) {
      await authMethod?.applySecurityAuthentication(requestContext);
    }

    return requestContext;
  }

  /**
   * Override Data Classes
   * @param projectId The project ID
   * @param id The id of the resource
   * @param overrideDataClassificationsRequest list of data classifications to override
   */
  public async overrideDataClasses(projectId: string, id: string, overrideDataClassificationsRequest: OverrideDataClassificationsRequest, _options?: Configuration): Promise<RequestContext> {
    let _config;
    if (!_options) {
      _config = this.configuration;
    } else {
      _config = Object.assign({}, _options, this.configuration);
    }
    


    // verify required parameter 'projectId' is not null or undefined
    if (projectId === null || projectId === undefined) {
      throw new RequiredError("ResourcesApi", "overrideDataClasses", "projectId");
    }


    // verify required parameter 'id' is not null or undefined
    if (id === null || id === undefined) {
      throw new RequiredError("ResourcesApi", "overrideDataClasses", "id");
    }


    // verify required parameter 'overrideDataClassificationsRequest' is not null or undefined
    if (overrideDataClassificationsRequest === null || overrideDataClassificationsRequest === undefined) {
      throw new RequiredError("ResourcesApi", "overrideDataClasses", "overrideDataClassificationsRequest");
    }


    // Path Params
    const localVarPath = '/v1/projects/{projectId}/resources/{id}/data-classifications'
      .replace('{' + 'projectId' + '}', encodeURIComponent(String(projectId)))
      .replace('{' + 'id' + '}', encodeURIComponent(String(id)));

    let signal = _config.timeout ? AbortSignal.timeout(_config.timeout) : undefined;
    // Make Request Context
    const requestContext = _config.baseServer!.makeRequestContext(localVarPath, HttpMethod.PATCH, signal);
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


    // Body Params
    const contentType = "application/json"
    requestContext.setHeaderParam("Content-Type", contentType);
    const serializedBody = JSON.stringify(ObjectSerializer.serialize(overrideDataClassificationsRequest, "OverrideDataClassificationsRequest", ""));
    requestContext.setBody(serializedBody);

    let authMethod: SecurityAuthentication | undefined;
    // Apply auth methods
    authMethod = _config.authMethods!["ApiKeyAuth"]
    if (authMethod?.applySecurityAuthentication) {
      await authMethod?.applySecurityAuthentication(requestContext);
    }

    return requestContext;
  }

  /**
   * Override Environment
   * @param projectId The project ID
   * @param id The id of the resource
   * @param overrideEnvironmentRequest environment to delete
   */
  public async overrideEnvironment(projectId: string, id: string, overrideEnvironmentRequest: OverrideEnvironmentRequest, _options?: Configuration): Promise<RequestContext> {
    let _config;
    if (!_options) {
      _config = this.configuration;
    } else {
      _config = Object.assign({}, _options, this.configuration);
    }
    


    // verify required parameter 'projectId' is not null or undefined
    if (projectId === null || projectId === undefined) {
      throw new RequiredError("ResourcesApi", "overrideEnvironment", "projectId");
    }


    // verify required parameter 'id' is not null or undefined
    if (id === null || id === undefined) {
      throw new RequiredError("ResourcesApi", "overrideEnvironment", "id");
    }


    // verify required parameter 'overrideEnvironmentRequest' is not null or undefined
    if (overrideEnvironmentRequest === null || overrideEnvironmentRequest === undefined) {
      throw new RequiredError("ResourcesApi", "overrideEnvironment", "overrideEnvironmentRequest");
    }


    // Path Params
    const localVarPath = '/v1/projects/{projectId}/resources/{id}/environments'
      .replace('{' + 'projectId' + '}', encodeURIComponent(String(projectId)))
      .replace('{' + 'id' + '}', encodeURIComponent(String(id)));

    let signal = _config.timeout ? AbortSignal.timeout(_config.timeout) : undefined;
    // Make Request Context
    const requestContext = _config.baseServer!.makeRequestContext(localVarPath, HttpMethod.PATCH, signal);
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


    // Body Params
    const contentType = "application/json"
    requestContext.setHeaderParam("Content-Type", contentType);
    const serializedBody = JSON.stringify(ObjectSerializer.serialize(overrideEnvironmentRequest, "OverrideEnvironmentRequest", ""));
    requestContext.setBody(serializedBody);

    let authMethod: SecurityAuthentication | undefined;
    // Apply auth methods
    authMethod = _config.authMethods!["ApiKeyAuth"]
    if (authMethod?.applySecurityAuthentication) {
      await authMethod?.applySecurityAuthentication(requestContext);
    }

    return requestContext;
  }

  /**
   * Remove Data Classes Override
   * @param projectId The project ID
   * @param id The id of the resource
   */
  public async removeDataClassesOverride(projectId: string, id: string, _options?: Configuration): Promise<RequestContext> {
    let _config;
    if (!_options) {
      _config = this.configuration;
    } else {
      _config = Object.assign({}, _options, this.configuration);
    }
    


    // verify required parameter 'projectId' is not null or undefined
    if (projectId === null || projectId === undefined) {
      throw new RequiredError("ResourcesApi", "removeDataClassesOverride", "projectId");
    }


    // verify required parameter 'id' is not null or undefined
    if (id === null || id === undefined) {
      throw new RequiredError("ResourcesApi", "removeDataClassesOverride", "id");
    }


    // Path Params
    const localVarPath = '/v1/projects/{projectId}/resources/{id}/data-classifications'
      .replace('{' + 'projectId' + '}', encodeURIComponent(String(projectId)))
      .replace('{' + 'id' + '}', encodeURIComponent(String(id)));

    let signal = _config.timeout ? AbortSignal.timeout(_config.timeout) : undefined;
    // Make Request Context
    const requestContext = _config.baseServer!.makeRequestContext(localVarPath, HttpMethod.DELETE, signal);
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


    let authMethod: SecurityAuthentication | undefined;
    // Apply auth methods
    authMethod = _config.authMethods!["ApiKeyAuth"]
    if (authMethod?.applySecurityAuthentication) {
      await authMethod?.applySecurityAuthentication(requestContext);
    }

    return requestContext;
  }

  /**
   * Remove Environment Override
   * @param projectId The project ID
   * @param id The id of the resource
   */
  public async removeEnvironmentOverride(projectId: string, id: string, _options?: Configuration): Promise<RequestContext> {
    let _config;
    if (!_options) {
      _config = this.configuration;
    } else {
      _config = Object.assign({}, _options, this.configuration);
    }
    


    // verify required parameter 'projectId' is not null or undefined
    if (projectId === null || projectId === undefined) {
      throw new RequiredError("ResourcesApi", "removeEnvironmentOverride", "projectId");
    }


    // verify required parameter 'id' is not null or undefined
    if (id === null || id === undefined) {
      throw new RequiredError("ResourcesApi", "removeEnvironmentOverride", "id");
    }


    // Path Params
    const localVarPath = '/v1/projects/{projectId}/resources/{id}/environments'
      .replace('{' + 'projectId' + '}', encodeURIComponent(String(projectId)))
      .replace('{' + 'id' + '}', encodeURIComponent(String(id)));

    let signal = _config.timeout ? AbortSignal.timeout(_config.timeout) : undefined;
    // Make Request Context
    const requestContext = _config.baseServer!.makeRequestContext(localVarPath, HttpMethod.DELETE, signal);
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


    let authMethod: SecurityAuthentication | undefined;
    // Apply auth methods
    authMethod = _config.authMethods!["ApiKeyAuth"]
    if (authMethod?.applySecurityAuthentication) {
      await authMethod?.applySecurityAuthentication(requestContext);
    }

    return requestContext;
  }

}

export class ResourcesApiResponseProcessor {

  /**
   * Unwraps the actual response sent by the server from the response context and deserializes the response content
   * to the expected objects
   *
   * @params response Response returned by the server for a request to cancelResourceBackupExclusion
   * @throws ApiException if the response code was not in [200, 299]
   */
   public async cancelResourceBackupExclusionWithHttpInfo(response: ResponseContext): Promise<HttpInfo<CancelExclusionFromBackupResponse >> {
    if (isCodeInRange("200", response.httpStatusCode)) {
      const body: CancelExclusionFromBackupResponse = ObjectSerializer.deserialize(
        await response.body.json(),
        "CancelExclusionFromBackupResponse", ""
      ) as CancelExclusionFromBackupResponse;
      return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
    }
    if (isCodeInRange("404", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Resource not found", undefined, response.headers, response);
    }
    if (isCodeInRange("1XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Informational", undefined, response.headers, response);
    }
    if (isCodeInRange("3XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Redirect", undefined, response.headers, response);
    }
    if (isCodeInRange("4XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Client Error", body, response.headers, response);
    }
    if (isCodeInRange("5XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Internal Server Error", body, response.headers, response);
    }

    // Work around for missing responses in specification, e.g. for petstore.yaml
    if (response.httpStatusCode > 200 || response.httpStatusCode > 299) {
      throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers, response);
    }
    const body: CancelExclusionFromBackupResponse = await response.body.json() as CancelExclusionFromBackupResponse;
    return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
  }

  /**
   * Unwraps the actual response sent by the server from the response context and deserializes the response content
   * to the expected objects
   *
   * @params response Response returned by the server for a request to excludeResourceFromBackup
   * @throws ApiException if the response code was not in [200, 299]
   */
   public async excludeResourceFromBackupWithHttpInfo(response: ResponseContext): Promise<HttpInfo<ExcludeFromBackupResponse >> {
    if (isCodeInRange("200", response.httpStatusCode)) {
      const body: ExcludeFromBackupResponse = ObjectSerializer.deserialize(
        await response.body.json(),
        "ExcludeFromBackupResponse", ""
      ) as ExcludeFromBackupResponse;
      return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
    }
    if (isCodeInRange("404", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Resource not found", undefined, response.headers, response);
    }
    if (isCodeInRange("1XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Informational", undefined, response.headers, response);
    }
    if (isCodeInRange("3XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Redirect", undefined, response.headers, response);
    }
    if (isCodeInRange("4XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Client Error", body, response.headers, response);
    }
    if (isCodeInRange("5XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Internal Server Error", body, response.headers, response);
    }

    // Work around for missing responses in specification, e.g. for petstore.yaml
    if (response.httpStatusCode > 200 || response.httpStatusCode > 299) {
      throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers, response);
    }
    const body: ExcludeFromBackupResponse = await response.body.json() as ExcludeFromBackupResponse;
    return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
  }

  /**
   * Unwraps the actual response sent by the server from the response context and deserializes the response content
   * to the expected objects
   *
   * @params response Response returned by the server for a request to getResource
   * @throws ApiException if the response code was not in [200, 299]
   */
   public async getResourceWithHttpInfo(response: ResponseContext): Promise<HttpInfo<GetResourceResponse >> {
    if (isCodeInRange("200", response.httpStatusCode)) {
      const body: GetResourceResponse = ObjectSerializer.deserialize(
        await response.body.json(),
        "GetResourceResponse", ""
      ) as GetResourceResponse;
      return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
    }
    if (isCodeInRange("404", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Inventory item not found", undefined, response.headers, response);
    }
    if (isCodeInRange("1XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Informational", undefined, response.headers, response);
    }
    if (isCodeInRange("3XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Redirect", undefined, response.headers, response);
    }
    if (isCodeInRange("4XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Client Error", body, response.headers, response);
    }
    if (isCodeInRange("5XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Internal Server Error", body, response.headers, response);
    }

    // Work around for missing responses in specification, e.g. for petstore.yaml
    if (response.httpStatusCode > 200 || response.httpStatusCode > 299) {
      throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers, response);
    }
    const body: GetResourceResponse = await response.body.json() as GetResourceResponse;
    return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
  }

  /**
   * Unwraps the actual response sent by the server from the response context and deserializes the response content
   * to the expected objects
   *
   * @params response Response returned by the server for a request to listResources
   * @throws ApiException if the response code was not in [200, 299]
   */
   public async listResourcesWithHttpInfo(response: ResponseContext): Promise<HttpInfo<ListResourcesResponse >> {
    if (isCodeInRange("200", response.httpStatusCode)) {
      const body: ListResourcesResponse = ObjectSerializer.deserialize(
        await response.body.json(),
        "ListResourcesResponse", ""
      ) as ListResourcesResponse;
      return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
    }
    if (isCodeInRange("1XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Informational", undefined, response.headers, response);
    }
    if (isCodeInRange("3XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Redirect", undefined, response.headers, response);
    }
    if (isCodeInRange("4XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Client Error", body, response.headers, response);
    }
    if (isCodeInRange("5XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Internal Server Error", body, response.headers, response);
    }

    // Work around for missing responses in specification, e.g. for petstore.yaml
    if (response.httpStatusCode > 200 || response.httpStatusCode > 299) {
      throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers, response);
    }
    const body: ListResourcesResponse = await response.body.json() as ListResourcesResponse;
    return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
  }

  /**
   * Unwraps the actual response sent by the server from the response context and deserializes the response content
   * to the expected objects
   *
   * @params response Response returned by the server for a request to overrideDataClasses
   * @throws ApiException if the response code was not in [200, 299]
   */
   public async overrideDataClassesWithHttpInfo(response: ResponseContext): Promise<HttpInfo<OverrideDataClassificationsResponse >> {
    if (isCodeInRange("200", response.httpStatusCode)) {
      const body: OverrideDataClassificationsResponse = ObjectSerializer.deserialize(
        await response.body.json(),
        "OverrideDataClassificationsResponse", ""
      ) as OverrideDataClassificationsResponse;
      return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
    }
    if (isCodeInRange("404", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Inventory item not found", undefined, response.headers, response);
    }
    if (isCodeInRange("1XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Informational", undefined, response.headers, response);
    }
    if (isCodeInRange("3XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Redirect", undefined, response.headers, response);
    }
    if (isCodeInRange("4XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Client Error", body, response.headers, response);
    }
    if (isCodeInRange("5XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Internal Server Error", body, response.headers, response);
    }

    // Work around for missing responses in specification, e.g. for petstore.yaml
    if (response.httpStatusCode > 200 || response.httpStatusCode > 299) {
      throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers, response);
    }
    const body: OverrideDataClassificationsResponse = await response.body.json() as OverrideDataClassificationsResponse;
    return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
  }

  /**
   * Unwraps the actual response sent by the server from the response context and deserializes the response content
   * to the expected objects
   *
   * @params response Response returned by the server for a request to overrideEnvironment
   * @throws ApiException if the response code was not in [200, 299]
   */
   public async overrideEnvironmentWithHttpInfo(response: ResponseContext): Promise<HttpInfo<OverrideEnvironmentResponse >> {
    if (isCodeInRange("200", response.httpStatusCode)) {
      const body: OverrideEnvironmentResponse = ObjectSerializer.deserialize(
        await response.body.json(),
        "OverrideEnvironmentResponse", ""
      ) as OverrideEnvironmentResponse;
      return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
    }
    if (isCodeInRange("404", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Inventory item not found", undefined, response.headers, response);
    }
    if (isCodeInRange("1XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Informational", undefined, response.headers, response);
    }
    if (isCodeInRange("3XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Redirect", undefined, response.headers, response);
    }
    if (isCodeInRange("4XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Client Error", body, response.headers, response);
    }
    if (isCodeInRange("5XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Internal Server Error", body, response.headers, response);
    }

    // Work around for missing responses in specification, e.g. for petstore.yaml
    if (response.httpStatusCode > 200 || response.httpStatusCode > 299) {
      throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers, response);
    }
    const body: OverrideEnvironmentResponse = await response.body.json() as OverrideEnvironmentResponse;
    return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
  }

  /**
   * Unwraps the actual response sent by the server from the response context and deserializes the response content
   * to the expected objects
   *
   * @params response Response returned by the server for a request to removeDataClassesOverride
   * @throws ApiException if the response code was not in [200, 299]
   */
   public async removeDataClassesOverrideWithHttpInfo(response: ResponseContext): Promise<HttpInfo<void >> {
    if (isCodeInRange("204", response.httpStatusCode)) {
      return new HttpInfo(response.httpStatusCode, response.headers, response.body, undefined);
    }
    if (isCodeInRange("404", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Inventory item not found", undefined, response.headers, response);
    }
    if (isCodeInRange("1XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Informational", undefined, response.headers, response);
    }
    if (isCodeInRange("3XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Redirect", undefined, response.headers, response);
    }
    if (isCodeInRange("4XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Client Error", body, response.headers, response);
    }
    if (isCodeInRange("5XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Internal Server Error", body, response.headers, response);
    }

    // Work around for missing responses in specification, e.g. for petstore.yaml
    if (response.httpStatusCode > 200 || response.httpStatusCode > 299) {
      throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers, response);
    }
    const body: void = await response.body.json() as void;
    return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
  }

  /**
   * Unwraps the actual response sent by the server from the response context and deserializes the response content
   * to the expected objects
   *
   * @params response Response returned by the server for a request to removeEnvironmentOverride
   * @throws ApiException if the response code was not in [200, 299]
   */
   public async removeEnvironmentOverrideWithHttpInfo(response: ResponseContext): Promise<HttpInfo<void >> {
    if (isCodeInRange("204", response.httpStatusCode)) {
      return new HttpInfo(response.httpStatusCode, response.headers, response.body, undefined);
    }
    if (isCodeInRange("404", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Inventory item not found", undefined, response.headers, response);
    }
    if (isCodeInRange("1XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Informational", undefined, response.headers, response);
    }
    if (isCodeInRange("3XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Redirect", undefined, response.headers, response);
    }
    if (isCodeInRange("4XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Client Error", body, response.headers, response);
    }
    if (isCodeInRange("5XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Internal Server Error", body, response.headers, response);
    }

    // Work around for missing responses in specification, e.g. for petstore.yaml
    if (response.httpStatusCode > 200 || response.httpStatusCode > 299) {
      throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers, response);
    }
    const body: void = await response.body.json() as void;
    return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
  }

}
