/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class SnapshotStorage {
  /**
  * The backup storage size (in bytes) of the inventory item
  */
  'sizeBytes'?: number;
  /**
  * The number of eon snapshots of the inventory item
  */
  'eonSnapshotsCount'?: number;
  /**
  * The number of non-eon snapshots of the inventory item
  */
  'otherSnapshotCount'?: number;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "sizeBytes",
      "baseName": "sizeBytes",
      "type": "number",
      "format": "int64"
    },
    {
      "name": "eonSnapshotsCount",
      "baseName": "eonSnapshotsCount",
      "type": "number",
      "format": ""
    },
    {
      "name": "otherSnapshotCount",
      "baseName": "otherSnapshotCount",
      "type": "number",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return SnapshotStorage.attributeTypeMap;
  }

  public constructor() {
  }
}

