/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class RequestDetails {
  /**
  * The ID of the request
  */
  'requestId'?: string;
  /**
  * The time of the request
  */
  'requestTime'?: Date;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "requestId",
      "baseName": "requestId",
      "type": "string",
      "format": ""
    },
    {
      "name": "requestTime",
      "baseName": "requestTime",
      "type": "Date",
      "format": "date-time"
    }  ];

  static getAttributeTypeMap() {
    return RequestDetails.attributeTypeMap;
  }

  public constructor() {
  }
}

